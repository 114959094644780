import { useContext, useState, createContext } from "react";
import type { ComponentType, } from "react"
import PopUp from "../pop-up";
import { useRouter } from "next/router";


export const ModalContext = createContext<ModalHandle | null>(null);

export interface modalState {
    props?: any
    compo?: ComponentType
    shown: boolean
}



export const useModal = () => useContext(ModalContext) as ModalHandle

export const useModalState = () => {
    const [state, setState] = useState<modalState>({ shown: false })

    const show = (compo: ComponentType, props: any) => {
        setState({
            shown: true,
            compo,
            props,
        })
    }

    const close = () => {
        setState({
            shown: false
        })
    }

    const showPopup = (content: string) => {
        show(PopUp, {
            popup_display: true,
            content,
            closePopup:(next: boolean) => {
                setState({
                    shown: next
                })
            }
        })
    }


    return { _inner_state: state, show, close, showPopup }
}


export type ModalHandle = ReturnType<typeof useModalState>

