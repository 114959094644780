const DEVELOPMENT_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const QA_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const STAGING_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const PRODUCTION_BASE_URL = process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT;

const timeout = 120000;

// fixme => i donot know the sensible value we cannot use node.process.env polyfill so cannot use env
const MAX_STORE_RADIUS = 100

const config = {
  showDebugUI: !!process.env.NEXT_PUBLIC_SHOW_DEBUG_UI,
  environment:process.env.NODE_ENV || "production",
  ECommerce: {
    BASE_URL: process.env.ECOMMERCE_BACKEND_SERVICE_ENDPOINT,
    CUSTOMER_URL: process.env.ECOMMERCE_CUSTOMER_SERVICE_ENDPOINT,
    ORDER_URL: process.env.ECOMMERCE_ORDER_SERVICE_ENDPOINT,
    STORE_URL: process.env.ECOMMERCE_STORE_SERVICE_ENDPOINT,
    GOOGLE_MAPS_GEOCODE_URL: `https://maps.googleapis.com/maps/api/geocode/json?address`,
    GOOGLE_MAPS_KEY: process.env.GOOGLE_API_KEY,
    GOOGLE_GTMID: process.env.NEXT_PUBLIC_BIG_BROTHER_KEY, // Google analytics / tag manager
    IVERI_REQUEST_URL:
      "https://portal.nedsecure.co.za/threedsecure/EnrollmentInitial",
    IVERI_RETURN_URL: `${process.env.ECOMMERCE_ORDER_SERVICE_ENDPOINT}/updateOrderPaymentDetails`,
    IVERI_APPLICATION_ID: "a6aee1c1-78eb-4b59-a9b4-fa98c6d3adfd",
    CURRENCY: "ZAR",
    timeout,
  },
  isDev: false,
  isDevPackage: false,
  isLocal: false,
  persistorPurge: false,
  version: 10,
  max_store_radius: MAX_STORE_RADIUS,
  currencySymbol: "R",
  EncrytionDecryptionRequired: false,
};



const getConfig = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      config.ECommerce.BASE_URL = DEVELOPMENT_BASE_URL;
      config.isDev = true;
      return config
    case "qa":
    case "preview":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || QA_BASE_URL;
      return config
    case "staging":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || STAGING_BASE_URL;
      return config
    case "production":
      config.ECommerce.BASE_URL = config.ECommerce.BASE_URL || PRODUCTION_BASE_URL;
      return config
    default:
      throw new Error(
        "Could not determine environment. Please define environment variable ENV."
      );
  }
};

export default getConfig();
