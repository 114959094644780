import { useEffect, useState } from "react"
import { getAuthToken, setAuthToken } from "./helper"
import { LoginResponse } from "../../api/user"
import { setCookie } from "../../utils/commonFunction"


export interface AuthState {
    loggedIn: boolean
    userDetail?: UserDetail
    isFirstSignUp?: boolean
    justChangedPassword?: boolean
}


export interface UserDetail {
    firstName: string
    lastName: string
    email: string
    uuid: string
}


export const useAuthState = () => {
    const [state, setState] = useState<AuthState>({ loggedIn: false })

    useEffect(() => {
        if (!window || !localStorage) {
            return
        }

        if (!state.loggedIn) {

            const tok = getAuthToken()
            if (!tok) {
                return
            } 

            const authPayload = localStorage.getItem("authPayload")
            if (authPayload) {
                const authPayloadJson = JSON.parse(authPayload)
                setState({ loggedIn: true, userDetail: authPayloadJson })
            }
        }


    }, [])

    const applyLoginFinish = (payload: LoginResponse) => {

        setAuthToken({ 
            accessToken: payload.accessToken as string, 
            refreshToken: payload.refreshToken as string 
        })

        setCookie("dxs", "logged", 30);
        setAuthState({
            loggedIn: true,
            userDetail: {
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.email,
                uuid: payload.uuid
            },
            isFirstSignUp: false,
            justChangedPassword: false
        })
    }


    const setAuthState = (authPayload: AuthState) => {
        setState(authPayload)
        localStorage.setItem("authPayload", JSON.stringify(authPayload))
    }

    const clearLoginState = () => {
        setAuthState({ loggedIn: false})
        localStorage.removeItem("authPayload")
    }
    
    const clearSignupFlag = () => {
        setState({ ...state, isFirstSignUp: false })
    }

    return { 
        state, 
        clearLoginState, 
        clearSignupFlag,
        applyLoginFinish
    }
}


export type Authhandle = ReturnType<typeof useAuthState> 




