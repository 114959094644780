import { createContext, useContext, useState } from "react";
import { useAuthState } from "../useAuth/useAuth";
import { useCartState } from "../../containers0/MenuItemRenderer/SuperCart/cart";
import useActiveStore, { ServiceType } from "../useActiveStore/useActiveStore";
import { useRouter } from "next/router";
import * as api from "../../api";

const getMenuPageURL = (store: any) => {
  const modifiedStoreName = store?.name.toLowerCase().replace(/\s+/g, "-");
  return `/menu/${modifiedStoreName}/${store?.uuid || ""}`;
};

export const useRootAppState = () => {
  const auth = useAuthState();
  const cart = useCartState();
  const activeStore = useActiveStore();
  const router = useRouter();

  const [hookHandles, setHookHandles] = useState<Record<string, any>>({});

  const registerHook = (name: string, hook: any) => {
    setHookHandles((old) => ({ ...old, [name]: hook }));
  };

  const unRegisterHook = (name: string) => {
    setHookHandles((old) => {
      const newHandles = { ...old };
      delete newHandles[name];
      return newHandles;
    });
  };

  const selectStore = (store: any, serviceType: ServiceType) => {
    cart.clearItems();
    activeStore.setStore(store);
    router.push(getMenuPageURL(store));

    (async () => {
      const resp = await api.getStoreById(store.id);
      if (resp?.status !== 200) {
        return;
      }
      activeStore.setStoreWithServiceType(resp?.data, serviceType);
    })();
  };

  const selectStoreWithFullInfo = (store: any, serviceType: any) => {
    cart.clearItems();
    activeStore.setStoreWithServiceType(store, serviceType);
    router.push(getMenuPageURL(store));
  };

  return {
    
    hookHandles,
    auth,
    cart,
    activeStore,
    registerHook,
    unRegisterHook,
    selectStoreWithFullInfo,
    selectStore,
  };
};

export type AppHandler = ReturnType<typeof useRootAppState>;

export const AppContext = createContext<AppHandler | null>(null);

export const useRootApp = () => useContext(AppContext) as AppHandler;
