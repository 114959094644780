import Modal from "react-modal";
import styles from "./styles.module.scss";
import { useModalState, ModalContext } from "./modal"

const WithMainModal = ({ children }: any) => {

  const state = useModalState();
  const { _inner_state: { shown, compo: InnerComponent, props } } = state


  return (

    <>
      <div className={styles.PopUp}>
        <Modal
          isOpen={shown}
          portalClassName={styles.modal}
          contentLabel="Modal"
        >
          <div className={styles.overlay} >

            {(shown && InnerComponent) && <InnerComponent {...(props || {})}></InnerComponent>}

          </div>
        </Modal>
      </div>

      <ModalContext.Provider value={state}>
        {children}
      </ModalContext.Provider>

    </>

  );
};


export default WithMainModal;
