export const myLoader = ({ src, width, quality }: any) =>
  `${src}?tr=w-${width},q-${quality || 80}`;

export const customSizes = () => {
  return "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw";
};

export const CUSTOM_SIZES = "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw";


export const logoSizes = () => {
  return "(max-width: 768px) 25vw, (max-width: 1200px) 50vw, 33vw";
};

export const bannerSizes = () => {
  return "(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 33vw";
};

