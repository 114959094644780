import { useEffect, useState } from "react";
import { StoreInfo } from "../../api/store/storeQueryTypes";
import { StoreDetailedInfo } from "../../state/models";

export interface State {
  store: StoreInfo | StoreDetailedInfo | null;
  serviceType?: ServiceType;
}

export type ServiceType =
  | "TAKE_AWAY"
  | "DELIVERY"
  | "DINE_IN"
  | "KERBSIDE_COLLEC";

const useActiveStore = () => {
  const [state, setState] = useState<State>({
    store: null,
  });

  useEffect(() => {
    if (!window || !window.localStorage) {
      return;
    }

    const data = localStorage.getItem("activeStore");
    if (data) {
      setState({ ...JSON.parse(data) });
    }
  }, []);

  useEffect(() => {
    if (!window || !window.localStorage) {
      return;
    }

    localStorage.setItem("activeStore", JSON.stringify(state));
  }, [state]);

  const setStore = (store: StoreInfo) => {
    setState({ store });
  };

  const setStoreWithServiceType = (
    store: StoreInfo,
    serviceType: ServiceType
  ) => {
    setState({ store, serviceType });
  };

  return {
    state,
    setStore,
    setStoreWithServiceType,
  };
};

export type ActiveStoreHandle = ReturnType<typeof useActiveStore>;

export default useActiveStore;
