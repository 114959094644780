export const PopUpData = {
  already_logged: {
    title: "Hello Romanite",
    content: `<p>We see you already have a profile with us.\n
        Please Login to continue</p>`,
    card_background: "#196BB3",
    text_color: "#FFFFFF",
  },
  complete_signup: {
    title: "Hello Romanite",
    content: `<p>
            We see you have already begun creating a profile with us. Please verify your number to complete the signup.
        </p>`,
    card_background: "#196BB3",
    text_color: "#FFFFFF",
    button: {
      label: "verify my number",
      text_color: "#FFFFFF",
      background: "#E9121B",
    },
  },
  profile_complete: {
    title: "Welcome to the Roman’s Pizza family!",
    content: `<p>
          Your profile has been successfully created
        </p>`,
    card_background: "#196BB3",
    text_color: "#FFFFFF",
  },
  password_reset: {
    title: "Success!",
    content: `<p>
          Your password has been reset
        </p>`,
    card_background: "#196BB3",
    text_color: "#FFFFFF",
  },
  something_went_wrong: "Something went wrong.Please try again later.",
};
