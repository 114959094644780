import Image from "next/image";
import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { logoSizes } from "../../../utils/imgUtils";
import { useCart } from "../../../containers0/MenuItemRenderer/SuperCart";
import { useClient } from "../../../hooks/useClientState";
import cloudflareLoader from "../../../Scripts/cloudflareCustomImageLoader";
import { useRootApp } from "../../../hooks/useRootAppState/useRootAppState";

const FallbackLogo =
  "https://serveup.ams3.cdn.digitaloceanspaces.com/demo/images/simplePlaceholder.png";

const Nav = () => {
  const router = useRouter();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const rootApp = useRootApp();
  const authState = rootApp.auth.state;
  const activeStore = rootApp.activeStore.state;

  const cart = rootApp.cart;
  const cdata = useClient();

  const totalCartItemCount = Object.keys(cart.state.items).length;

  const logoIcon = ((cdata.data["clientMeta"] || {})["styleJson"] || {})[
    "LogoIcon"
  ];

  useEffect(() => {
    if (overlayOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [overlayOpen]);

  const [menuLink, setMenuLink] = useState("/all-stores");

  useEffect(() => {
    const modifiedStoreName = activeStore.store?.name
      ?.toLowerCase()
      .replace(/\s+/g, "-");
    if (modifiedStoreName) {
      setMenuLink(`/menu/${modifiedStoreName}/${activeStore.store?.uuid}`);
    }
  }, [activeStore]);

  const toggleOverlay = () => {
    setOverlayOpen(!overlayOpen);
  };



  return (
    <>
      <div className="flex flex-col items-start self-stretch  max-h-screen fixed w-full z-[100] text-white sup-header">
        <div className="flex h-[80px] flex-shrink-0 px-[20px] justify-between	items-center self-stretch md:max-w-[1100px] md:w-full md:mx-auto z-[100]">
          <div
            className="flex w-[51px] px-[2px] py-[5px] flex-col items-start gap-[10px] md:hidden cursor-pointer pointer-events-auto"
            onClick={() => setOverlayOpen(!overlayOpen)}
          >
            <div className="w-[21px] h-[15px] relative">
              <svg>
                <use href="/sprites/sprites.svg#hamburger" />
              </svg>
            </div>
          </div>
          <div className="flex gap-[40px] items-center">
            <Link href="/" className="w-[48px] h-[46.745px] relative ">
              <Image
                loader={cloudflareLoader}
                src={logoIcon || FallbackLogo}
                alt="logo image"
                fill
                sizes={logoSizes()}
                style={{
                  objectFit: "cover",
                }}
              />
            </Link>

            <div className="hidden gap-[40px] items-center md:flex">
              <NavLink text="Home" url="/" isActive={router.pathname === "/"} />
              <NavLink
                text="Menu"
                url={menuLink}
                isActive={router.pathname.includes("menu")}
              />
              <NavLink
                text="Stores"
                url="/store-locator"
                isActive={router.pathname === "/store-locator"}
              />

              {authState.loggedIn && (
                <>
                  <NavLink
                    text="Profile"
                    url="/profile"
                    isActive={router.pathname.includes("profile")}
                  />
                </>
              )}
            </div>
          </div>
          <div className="flex gap-[40px] items-center">
            {!authState.loggedIn && (
              <>
                <Link
                  href={"/signup"}
                  className="text-center text-[16px] font-font-family-body font-semibold hidden md:block"
                >
                  Sign up
                </Link>
                <Link
                  href={"/login"}
                  className="text-center text-[16px] font-font-family-body font-semibold hidden md:block"
                >
                  Log in
                </Link>
              </>
            )}

            <Link href="/cart">
              <div className="flex p-[9px] items-center gap-[4px] rounded-[4px] sup-active-btn">
                <div className="w-[20px] h-[20px] justify-center items-center relative">
                  <svg>
                    <use href="/sprites/sprites.svg#cart" />
                  </svg>
                </div>
                <p className="font-font-family-body text-[18px] font-normal leading-normal">
                  {totalCartItemCount}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`h-screen w-full z-[99] bg-accents-primary-accent transition duration-[250ms] pt-[80px] p-[12px] fixed ${
          overlayOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <MobileNavLink
          text="Menu"
          url={menuLink}
          icon="menuIcon"
          isActive={false}
          onCLick={toggleOverlay}

        />
        <span className="block w-full bg-surface-background h-[1px] opacity-[0.3]" />

        <MobileNavLink
          text="Store"
          url="/store-locator"
          icon="storeIcon"
          isActive={false}
          onCLick={toggleOverlay}

        />
        <span className="block w-full bg-surface-background h-[1px] opacity-[0.3]" />

        {authState.loggedIn ? (
          <>
            <MobileNavLink
              text="Profile"
              url="/profile"
              icon="profileIcon"
              isActive={false}
              onCLick={toggleOverlay}
            />

            <span className="block w-full bg-surface-background h-[1px] opacity-[0.3]" />

            <MobileNavLink
              text="Log Out"
              url="/"
              icon="profileLogout"
              isActive={false}
              onCLick={() => {
                rootApp.auth.clearLoginState();
                router.push("/");
                toggleOverlay();
              }}
            />
          </>
        ) : (
          <>
            <MobileNavLink
              text="Register"
              url="/signup"
              icon="profile"
              isActive={false}
              onCLick={toggleOverlay}
            />
            <MobileNavLink
              text="Login"
              url="/login"
              icon="profileIcon"
              isActive={false}
              onCLick={toggleOverlay}
            />
          </>
        )}


      </div>
    </>
  );
};

interface NavLinkProps {
  text: string;
  url: string;
  isActive: boolean;
  icon?: string;
  onCLick?: () => void;
}

const NavLink = (props: NavLinkProps) => {
  return (
    <>
      {props.isActive ? (
        <div className="sup-active-btn rounded-[8px] py-[12px] px-[12px]">
          <p className="text-center text-[16px] font-font-family-body font-semibold">
            {props.text}
          </p>
        </div>
      ) : (
        <Link
          href={props.url}
          className="text-center text-[16px] font-font-family-body font-semibold"
        >
          {props.text}
        </Link>
      )}
    </>
  );
};

const MobileNavLink = (props: NavLinkProps) => {
  return (
    <>
      <Link
        href={props.url}
        onClick={props.onCLick}
        className="flex py-[12px] items-center gap-[12px] self-stretch h-[56px]"
      >
        <div className="w-[24px] h-[24px] relative">
          <svg>
            <use href={`/sprites/sprites.svg#${props.icon}`} />
          </svg>
        </div>
        <p className="text-center text-[16px] font-font-family-body font-semibold">
          {props.text}
        </p>
      </Link>
    </>
  );
};

export default Nav;
