import { decrypt, encrypt } from "../../utils/commonFunction"

const PRE_AUTH_KEY = "__pre_authed_token__"
const IS_PASSWORD_CAPTURE_KEY = "__reset_password_capture__"
const AUTH_TOKEN_KEY = "__auth_token__"
const RESET_TOKEN_KEY = "__reset_token__"


// pre auth

export const setPreAuthedToken = (value: string) => {
    const evalue = encrypt(value)

    localStorage.setItem(PRE_AUTH_KEY, evalue)
}

export const getPreAuthedToken = () => {
    const rvalue = localStorage.getItem(PRE_AUTH_KEY)
    if (rvalue) {
        return decrypt(rvalue)
    }
}

export const clearPreAuthedToken = () => {
    localStorage.removeItem(PRE_AUTH_KEY)
}


// reset token


export const setResetToken = (value: string, isCapturePass?: boolean) => {

    const evalue = encrypt(value)
    
    localStorage.setItem(RESET_TOKEN_KEY, evalue)


    if (isCapturePass) {
        localStorage.setItem(IS_PASSWORD_CAPTURE_KEY, "true")
    }
}

export const getResetToken = () => {
    const rvalue = localStorage.getItem(RESET_TOKEN_KEY)

    return {
        token: rvalue ? decrypt(rvalue) : "",
        isPasswordCapture:  localStorage.getItem(IS_PASSWORD_CAPTURE_KEY) === "true"
    }
}


export const clearResetToken = () => {
    localStorage.removeItem(PRE_AUTH_KEY)
    localStorage.removeItem(IS_PASSWORD_CAPTURE_KEY)
}


// auth token


interface userAuthData {
    accessToken: string
    refreshToken: string
}

export const setAuthToken = (value: userAuthData) => {
    const rawvalue = JSON.stringify(value)

    const evalue = encrypt(rawvalue)
    localStorage.setItem(AUTH_TOKEN_KEY, evalue)
}

export const getAuthToken = () => {
    if (!window || !localStorage) {
        return null
    }

    const rvalue = localStorage.getItem(AUTH_TOKEN_KEY)
    if (rvalue) {
        const rawvalue = decrypt(rvalue)
        return JSON.parse(rawvalue  as string ) as userAuthData
    }

    return null
}

export const clearAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY)
}


// phone


export const formatToSafePhone = (cellphone: string) => {
    const phone = encrypt(cellphone.replace(/\D/g, ""));
    const safePhone = phone.replace(/\//g, "_");
    return safePhone
};

export const formatToPhone = (safePhone: string) => {
    const phone = decrypt(safePhone.replace(/_/g, "/"));
    return phone
}


