import React, { useEffect, useState } from "react";
import { withRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import type { NextPageContext } from "next";

const ErrorBoundary = ({ children }: any) => {
  const [error, setError] = useState<Error | null>(null);
  const [errorInfo, setErrorInfo] = useState<React.ErrorInfo | null>(null);

  useEffect(() => {
    const errorHandler = (error: Error, errorInfo: React.ErrorInfo) => {
      setError(error);
      setErrorInfo(errorInfo);
    };

    const eHander = (event: ErrorEvent) => {
      console.log("ev_error", event);
      console.log("error", event.error);

      Sentry.captureException(event.error);

      errorHandler(event.error, error?.stack as any);
    };

    window.addEventListener("error", eHander);
    return () => {
      window.removeEventListener("error", eHander);
    };
  }, []);

  if (errorInfo) {
    return (
      <div className="text-text-text-primary">
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: "pre-wrap" }}>
          {error?.toString()}
          <br />
          {errorInfo.componentStack}
        </details>

        <details style={{ whiteSpace: "pre-wrap" }}>
          RAW Error
          <br />
          <pre>
          {JSON.stringify(error, null, 2)}
          </pre>
        </details>

        <button
          type="button"
          onClick={() => {
            setError(null);
            setErrorInfo(null);
          }}
        >
          Try again?
        </button>
      </div>
    );
  }

  return <>{children}</>;
};

export const getInitialProps = async (contextData: NextPageContext) => {
  console.log("@_error/getInitialProps", contextData);
  await Sentry.captureUnderscoreErrorException(contextData);
};

export default withRouter(ErrorBoundary);
