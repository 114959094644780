import { createContext, useContext, useEffect, useState } from "react"
import { ClientData, ClientMeta } from "../state/models/client"

declare global {
    interface Window {
        __client_data__: Record<string, any>
        __client_style__: Record<string, any>
        __d__: any
    }
}

export interface ClientStateType {
    data:  Partial<ClientData>, 
    style: Record<string, any>
}


const useClientState = () => {
    const [state, setState] = useState<ClientStateType>({
        data: {},
        style: {}
    })



    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }

        const nextdata = window["__client_data__"] || {}
        const nextstyle = window["__client_style__"] || {}

        setState({
            data: nextdata,
            style: nextstyle
        })
    }, [])
    
    return state
}


export type Clienthandle = ReturnType<typeof useClientState> 

export const ClientContext = createContext<Clienthandle | null>(null)

export const useClient = () => useContext(ClientContext) as Clienthandle;


export { useClientState }