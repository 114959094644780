import { ServiceType } from "../../state/models"
import { httpClient } from "../http"
import * as qtype from "./storeQueryTypes"




export const getAllStores = async () => {
    return httpClient.get<qtype.Result>("/stores/query/ecommerce")
}

export const queryStores = async (data: qtype.QueryOptions) => {
    return httpClient.post<qtype.Result>("/stores/query/ecommerce", data)
}

export const getStoreById = async (id: number) => {
    return httpClient.get<any>(`/stores/query/ecommerce/getFormattedStore/${id}`)
}

export interface PrecheckData {
    storeUuid: string,
    serviceType: string
    clientUuid: string
}

export interface PreResult {
    canAcceptOrder: boolean
    reason: string
}



export const orderPreCheck = async (data: PrecheckData) => {
    return httpClient.post<PreResult>("/order/precheck", data)
}

