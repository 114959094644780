import axios from "axios";
import config from "../config/app";
import * as interceptor from "../state/interceptor";


const { ECommerce } = config;


const httpClient = axios.create({
    baseURL: ECommerce.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: ECommerce.timeout,
    withCredentials: true,
});


httpClient.interceptors.request.use(interceptor.autoEncryptAndInjectAuth, interceptor.handleErr);
httpClient.interceptors.response.use(interceptor.autoDecrypt, interceptor.autoRefresh);


export type DataWrapper<D = any> = {
    success: true,
    status: number,
    data: D
} & {
    success: boolean,
    status: number,
    errorCode: string,
    errorMessage: string,
}



export { httpClient }