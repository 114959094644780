import { useEffect } from "react";

const useCustomizerStyles = () => {
  useEffect(() => {
    const applyStyles = (ev: any) => {
      console.log("applyStyles/i_got_the_gibies", ev);
      const stylesData = ev.data;

      const oldStyles = document.querySelectorAll("style[data-customizer]");
      if (oldStyles) {
        oldStyles.forEach((s) => s.remove());
      }

      const style = document.createElement("style");
      style.textContent = stylesData;
      style.dataset.customizer = "true";
      document.head.appendChild(style);
    };

    window.addEventListener("message", applyStyles);

    return () => {
      window.removeEventListener("message", applyStyles);
    };
  }, []);

  return {};
};

export default useCustomizerStyles;
