

import { useCartState, CartContext } from "./cart"

const SuperCartProvider = ({ children }: any) => {
    const handle = useCartState()


    return (<>
        <CartContext.Provider value={handle}>
            {children}
        </CartContext.Provider>
    </>)
}

    


export default SuperCartProvider;