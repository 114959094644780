import styles from "./styles.module.scss";
import { PopUpData } from "./data";
import Button from "../button";

const PopUp = (props: any) => {
  const {
    title,
    content,
    buttonContent,
    popup_display,
    closePopup,
    btnNavigation,
    verifyNoButton,
  } = props;

  return (
    <>
      {popup_display ? (
        <div className={styles.container}>
          <div className={styles.tail} />
          <div className={styles.card}>
            <span className={styles.icon}>
              <svg onClick={() => closePopup(false)}>
                <use href={"/sprites/sprites.svg#white-close"} />
              </svg>
            </span>
            <strong>{title}</strong>
            {content && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    content === "<p>[object Object]</p>" ||
                    content === "[object Object]" ||
                    content === "[Object Object]" ||
                    content === "[Object object]"
                      ? PopUpData.something_went_wrong
                      : `<p>${content}</p>`,
                }}
              />
            )}
            {buttonContent && (
              <Button
                disabled={verifyNoButton}
                func={btnNavigation}
                text={buttonContent ?? PopUpData.complete_signup.button.label}
                border={PopUpData.complete_signup.button.background}
                color={PopUpData.complete_signup.button.text_color}
                background={PopUpData.complete_signup.button.background}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PopUp;
