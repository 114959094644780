import Link from "next/link";
import { ActiveStoreHandle } from "../../hooks/useActiveStore/useActiveStore";

interface PropsType {
  handle: ActiveStoreHandle;
}

const ActiveStoreBar = (props: PropsType) => {
  return (
    <>
      {props.handle.state.store && (
        <>
          <div className="bg-accents-secondary-accent w-full sticky top-[80px] z-[50] sup-active-bar">
            <div className="flex flex-shrink-0 px-[20px] py-[12px] justify-between items-center self-stretch md:max-w-[1100px] md:w-full md:mx-auto gap-[10px]">
              <div className="flex gap-[8px] items-center">
                <div className="w-[24px] h-[24px] relative">
                  <svg>
                    <use href="/sprites/sprites.svg#infoIcon" />
                  </svg>
                </div>
                <div className="flex flex-col gap-[4px] items-start">
                  <p className="font-font-family-body text-[12px] italic font-normal">
                    Your store
                  </p>
                  <p className="font-font-family-body text-[16px] font-semibold">
                    {props.handle.state.store.name}
                  </p>
                </div>
              </div>
              <div>
                <Link href="/store-locator" className="accents-primary-accent flex p-[10px] rounded-[4px] self-stretch justify-center items-center w-full min-h-[40px] bg-accents-primary-accent ">
                Change
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActiveStoreBar;
