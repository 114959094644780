import { httpClient } from "../http"


interface PaymentInfo {
  status: "paid" | "unpaid"
  integratedPayments: any[]
}


export const getPaymentInfo = (orderId: string) => {
  return httpClient.get<PaymentInfo>(`/order/payment/status/${orderId}`)
}

export interface PayFlowInitRequest {
  orderUuid: string
  tokinizedCardId: string
} 

export interface PayFlowInitResponse {
  tokenizedCardIndex: string
  enrollmentEndpoint: string
  returnURL: string
  orderUuid: string
  applicationId: string
  encodedId: string
  amount: string
  expiryDate: string
  pan: string
  holderName: string
  paymentId: number
}

export const initPaymentFlow = (data: PayFlowInitRequest) => {
  return httpClient.post<PayFlowInitResponse>(`/order/payment/flow/init`, data)
}


export interface PaymentFlowStatus {
  orderPaymentStatus: string
  activePaymentStatus: string
}

export const getPaymentFlowStatus = (orderUuid: string, paymentId: number) => {
  return httpClient.get<PaymentFlowStatus>(`/order/payment/flow/status/${orderUuid}/${paymentId}`)
}


